// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen.web';
import Nav from '../../components/src/Nav.web';

import FooterSection from '../../components/src/FooterSection';

import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import AutomaticCheckoutCalculation2 from '../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MerchantDashboard2 from '../../blocks/MerchantDashboard2/src/MerchantDashboard2';
import PricingEngine2 from '../../blocks/PricingEngine2/src/PricingEngine2';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth.web';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Reports1 from '../../blocks/RolesPermissions2/src/Reports1.web';
import Payments from '../../blocks/Payments/src/Payments';
import InventoryManagement25 from '../../blocks/InventoryManagement25/src/InventoryManagement25';
import Videos from '../../blocks/videos/src/Videos';
import  OnlineFactoryVisit from '../../blocks/videos/src/OnlineFactoryVisit.web';
import Testimonials from '../../blocks/videos/src/Testimonials.web';
import Customform from '../../blocks/customform/src/Customform';
import TicketSystem from '../../blocks/TicketSystem/src/TicketSystem';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import MerchantOrder from '../../blocks/MerchantOrder/src/MerchantOrder';
import Addresses from '../../blocks/addressmanagement/src/Addresses';
import AddAddress from '../../blocks/addressmanagement/src/AddAddress';
import DocumentOpener from '../../blocks/documentopener/src/DocumentOpener';
import Filteritems from '../../blocks/filteritems/src/Filteritems.web';
import FilterVehicleCategory from '../../blocks/filteritems/src/FilterVehicleCategory.web';
import FilterVehicleManufacturer from '../../blocks/filteritems/src/FilterVehicleManufacturer.web';
import FileterVehicleModel from '../../blocks/filteritems/src/FileterVehicleModel.web';
import FilterVehicleFuel from '../../blocks/filteritems/src/FilterVehicleFuel.web';
import FilterShowBatteries from '../../blocks/filteritems/src/FilterShowBatteries.web';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import FilterInverterCapacity from '../../blocks/filteritems/src/FilterInverterCapacity.web';
import FilterInverterDCInput from '../../blocks/filteritems/src/FilterInverterDCInput.web';
import FilterInverterBackup from '../../blocks/filteritems/src/FilterInverterBackup.web';
import FilterBatteryCapacity from '../../blocks/filteritems/src/FilterBatteryCapacity.web';
import FilterInverterShowBatteries from '../../blocks/filteritems/src/FilterInverterShowBatteries.web';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import Interactivefaqs from '../../blocks/interactivefaqs/src/Interactivefaqs';
import AddInteractivefaqs from '../../blocks/interactivefaqs/src/AddInteractivefaqs';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Faq from '../../blocks/user-profile-basic/src/Faq.web';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import UserAddressEditDelete from '../../blocks/user-profile-basic/src/UserAddressEditDelete.web';
import UserProfileAddAddress from '../../blocks/user-profile-basic/src/UserProfileAddAddress.web';
import UserProfileEditAddress from '../../blocks/user-profile-basic/src/UserProfileEditAddress.web';
import ProductDescription from '../../blocks/ProductDescription/src/ProductDescription';
import ProductDescription1 from '../../blocks/ProductDescription/src/ProductDescription1.web';
import ProductDescription2 from '../../blocks/ProductDescription/src/ProductDescription2.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web';
import ThreeWheeler from '../../blocks/categoriessubcategories/src/ThreeWheeler.web';
import CarSuvMuvBatteries from '../../blocks/categoriessubcategories/src/CarSuvMuvBatteries.web';
import CommercialVehicle from '../../blocks/categoriessubcategories/src/CommercialVehicle.web';
import TractorDashboard from '../../blocks/categoriessubcategories/src/TractorDashboard.web';
import InverterDashboard from '../../blocks/categoriessubcategories/src/InverterDashboard.web';
import TermsCondition from '../../blocks/categoriessubcategories/src/TermsCondition.web';
import Feedback from '../../blocks/categoriessubcategories/src/Feedback.web';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import WhatsappInteg from '../../blocks/WhatsappInteg/src/WhatsappInteg';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import AutomaticReminders from '../../blocks/AutomaticReminders/src/AutomaticReminders';
import MultipageForms2 from '../../blocks/MultipageForms2/src/MultipageForms2';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import signUp from '../../blocks/email-account-registration/src/signUp.web';
import AllBatteries from '../../blocks/dashboard/src/AllBatteries.web';
import VehiclesBatteries from '../../blocks/dashboard/src/VehiclesBatteries.web';
import InverterBatteries from '../../blocks/dashboard/src/InverterBatteries.web';
import LoginRequired from '../../blocks/dashboard/src/LoginRequired.web';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import ImageThumbnail from '../../blocks/ImageThumbnail/src/ImageThumbnail';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import VideoManagement from '../../blocks/VideoManagement/src/VideoManagement.web';
import MediaAdvertisements from '../../blocks/VideoManagement/src/MediaAdvertisements.web';
import TimeTracker from '../../blocks/TimeTracker/src/TimeTracker';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import WarrantyManagement2 from '../../blocks/WarrantyManagement2/src/WarrantyManagement2';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import RequestCallBack from '../../blocks/contactus/src/RequestCallback';
import RegisterServiceCall from '../../blocks/contactus/src/RegisterServiceCall';
import WarrantyManagement from '../../blocks/RolesPermissions2/src/WarrantyManagement';
import WarrantyClaimRequest from '../../blocks/RolesPermissions2/src/WarrantyClaimRequest';
import MyRequest from '../../blocks/contactus/src/MyRequest';
import MyBatteryCollection from '../../blocks/ordermanagement/src/MyBatteryCollection';
import Orders from '../../blocks/ordermanagement/src/Orders';
import RateProduct from '../../blocks/ordermanagement/src/RateProduct';
import ProductInfo from '../../blocks/ordermanagement/src/ProductInfo';
import Return from '../../blocks/ordermanagement/src/Return';
import Exchange from '../../blocks/ordermanagement/src/Exchange';
import SelectProductToExchange from '../../blocks/ordermanagement/src/SelectProductToExchange';
import ExchangeMethod from '../../blocks/ordermanagement/src/ExchangeMethod.web';
import ConfirmExchange from '../../blocks/ordermanagement/src/ConfirmExchange';
import ExchangeSummary from '../../blocks/ordermanagement/src/ExchangeSummary';
import Payment from '../../blocks/ordermanagement/src/Payment';
import DealerReports from '../../blocks/MerchantDashboard2/src/DealerReports';
import Report from '../../blocks/MerchantDashboard2/src/Report.web';
import ProfileDetails from '../../blocks/MerchantDashboard2/src/ProfileDetails';
import InvoicesBillingHistory from '../../blocks/MerchantDashboard2/src/InvoicesBillingHistory';
import InventoryManagement from '../../blocks/MerchantDashboard2/src/InventoryManagement';
import TechnicianManagement from '../../blocks/MerchantDashboard2/src/TechnicianManagement';
import AddTechnician from '../../blocks/MerchantDashboard2/src/AddTechnician';
import WarrantyRegistration from '../../blocks/MerchantDashboard2/src/RegisterWarranty.web';
import ServiceCallRequest from '../../blocks/MerchantDashboard2/src/ServiceCallRequest';
import ManageBatteryCollection from '../../blocks/MerchantDashboard2/src/ManageBatteryCollection';
import TechnicianDashboard from '../../blocks/MerchantOrder/src/TechnicianDashboard';
import UpcomingServiceCall from '../../blocks/MerchantOrder/src/UpcomingServiceCall';
import TechnicianProfile from '../../blocks/MerchantOrder/src/TechnicianProfile';
import EditProfile from '../../blocks/MerchantOrder/src/EditProfile';
import SocialMedia1 from '../../blocks/social-media-account/src/SocialMedia1.web';
import SocialMedia2 from '../../blocks/social-media-account/src/SocialMedia2.web';
import SocialMedia3 from '../../blocks/social-media-account/src/SocialMedia3.web';
import Wishlist from '../../blocks/videos/src/Wishlist.web';

import AboutUs from '../../blocks/categoriessubcategories/src/AboutUs.web';
import ShippingPolicy from '../../blocks/categoriessubcategories/src/ShippingPolicy.web';
import PrivacyPolicy from '../../blocks/categoriessubcategories/src/PrivacyPolicy.web';
import MediaGallery from '../../blocks/VideoManagement/src/MediaGallery';
import Photos from '../../blocks/VideoManagement/src/Photos';
import Video from '../../blocks/VideoManagement/src/Videos';
import SearchBatteryResult from '../../components/src/SearchBatteryResults.web';
import EditTechnician from '../../blocks/MerchantDashboard2/src/EditTechnician.web';
import ManageTimeSlots from '../../blocks/MerchantDashboard2/src/ManageTimeSlots.web';
import path from 'path';

const routeMap = {
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: '/AutomaticCheckoutCalculation2'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MerchantDashboard2: {
    component: MerchantDashboard2,
    path: '/MerchantDashboard2'
  },
  PricingEngine2: {
    component: PricingEngine2,
    path: '/PricingEngine2'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders'
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Reports1: {
    component: Reports1,
    path: '/Reports1'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  InventoryManagement25: {
    component: InventoryManagement25,
    path: '/InventoryManagement25'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  OnlineFactoryVisit: {
    component: OnlineFactoryVisit,
    path: '/OnlineFactoryVisit'
  },
  Testimonials: {
    component: Testimonials,
    path: '/Testimonials'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  TicketSystem: {
    component: TicketSystem,
    path: '/TicketSystem'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  MerchantOrder: {
    component: MerchantOrder,
    path: '/MerchantOrder'
  },
  Addresses: {
    component: Addresses,
    path: '/Addresses'
  },
  AddAddress: {
    component: AddAddress,
    path: '/AddAddress'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  FilterVehicleCategory: {
    component: FilterVehicleCategory,
    path: '/FilterVehicleCategory'
  },
  FilterVehicleManufacturer: {
    component: FilterVehicleManufacturer,
    path: '/FilterVehicleManufacturer'
  },
  FileterVehicleModel: {
    component: FileterVehicleModel,
    path: '/FileterVehicleModel'
  },
  FilterVehicleFuel: {
    component: FilterVehicleFuel,
    path: '/FilterVehicleFuel'
  },
  FilterShowBatteries: {
    component: FilterShowBatteries,
    path: '/FilterShowBatteries'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  FilterInverterCapacity: {
    component: FilterInverterCapacity,
    path: '/FilterInverterCapacity'
  },
  FilterInverterDCInput: {
    component: FilterInverterDCInput,
    path: '/FilterInverterDCInput'
  },
  FilterInverterBackup: {
    component: FilterInverterBackup,
    path: '/FilterInverterBackup'
  },
  FilterBatteryCapacity: {
    component: FilterBatteryCapacity,
    path: '/FilterBatteryCapacity'
  },
  FilterInverterShowBatteries: {
    component: FilterInverterShowBatteries,
    path: '/FilterInverterShowBatteries'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs'
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Faq: {
    component: Faq,
    path: '/Faq'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserAddressEditDelete: {
    component: UserAddressEditDelete,
    path: '/UserAddressEditDelete'
  },
  UserProfileEditAddress: {
    component: UserProfileEditAddress,
    path: '/UserProfileEditAddress'
  },
  UserProfileAddAddress: {
    component: UserProfileAddAddress,
    path: '/UserProfileAddAddress'
  },
  ProductDescription: {
    component: ProductDescription,
    path: '/ProductDescription'
  },
  ProductDescription1: {
    component: ProductDescription1,
    path: '/ProductDescription1',
    
  },
  ProductDescription2: {
    component: ProductDescription2,
    path: '/ProductDescription2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  ThreeWheeler: {
    component: ThreeWheeler,
    path: '/ThreeWheeler'
  },
  CarSuvMuvBatteries: {
    component: CarSuvMuvBatteries,
    path: '/CarSuvMuvBatteries'
  },
  CommercialVehicle: {
    component: CommercialVehicle,
    path: '/CommercialVehicle'
  },
  TractorDashboard: {
    component: TractorDashboard,
    path: '/TractorDashboard'
  },
  InverterDashboard: {
    component: InverterDashboard,
    path: '/InverterDashboard'
  },
  TermsCondition: {
    component: TermsCondition,
    path: '/TermsCondition'
  },
  Feedback: {
    component: Feedback,
    path: '/Feedback'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  WhatsappInteg: {
    component: WhatsappInteg,
    path: '/WhatsappInteg'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  AutomaticReminders: {
    component: AutomaticReminders,
    path: '/AutomaticReminders'
  },
  MultipageForms2: {
    component: MultipageForms2,
    path: '/MultipageForms2'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  signUp: {
    component: signUp,
    path: '/signUp'
  },
  AllBatteries: {
    component: AllBatteries,
    path: '/AllBatteries',
  },
  VehiclesBatteries: {
    component: VehiclesBatteries,
    path: '/VehiclesBatteries'
  },
  InverterBatteries: {
    component: InverterBatteries,
    path: '/InverterBatteries'
  },
  LoginRequired: {
    component: LoginRequired,
    path: '/LoginRequired'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  ImageThumbnail: {
    component: ImageThumbnail,
    path: '/ImageThumbnail'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  VideoManagement: {
    component: VideoManagement,
    path: '/VideoManagement'
  },
  MediaAdvertisements: {
    component: MediaAdvertisements,
    path: '/MediaAdvertisements'
  },
  TimeTracker: {
    component: TimeTracker,
    path: '/TimeTracker'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  WarrantyManagement2: {
    component: WarrantyManagement2,
    path: '/WarrantyManagement2'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  RequestCallBack: {
    component: RequestCallBack,
    path: '/RequestCallBack'
  },
  RegisterServiceCall: {
    component: RegisterServiceCall,
    path: '/RegisterServiceCall'
  },
  WarrantyManagement: {
    component: WarrantyManagement,
    path: '/WarrantyManagement'
  },
  WarrantyClaimRequest: {
    component: WarrantyClaimRequest,
    path: '/WarrantyClaimRequest'
  },
  MyRequest: {
    component: MyRequest,
    path: '/MyRequest'
  },
  MyBatteryCollection: {
    component: MyBatteryCollection,
    path: '/MyBatteryCollection'
  },
  RateProduct: {
    component: RateProduct,
    path: '/RateProduct'
  },

  Orders: {
    component: Orders,
    path: '/Orders'
  },
  ProductInfo: {
    component: ProductInfo,
    path: '/ProductInfo'
  },
  Return: {
    component: Return,
    path: '/Return'
  },
  Exchange: {
    component: Exchange,
    path: '/Exchange'
  },
  SelectProductToExchange: {
    component: SelectProductToExchange,
    path: '/SelectProductToExchange'
  },
  ExchangeMethod: {
    component: ExchangeMethod,
    path: '/ExchangeMethod'
  },

  ConfirmExchange: {
    component: ConfirmExchange,
    path: '/ConfirmExchange'
  },

  ExchangeSummary: {
    component: ExchangeSummary,
    path: '/ExchangeSummary'
  },

  Payment: {
    component: Payment,
    path: '/Payment'
  },
  DealerReports: {
    component: DealerReports,
    path: '/DealerReports'
  },
  Report: {
    component: Report,
    path: '/Report'
  },
  ProfileDetails: {
    component: ProfileDetails,
    path: '/ProfileDetails'
  },
  InvoicesBillingHistory: {
    component: InvoicesBillingHistory,
    path: '/InvoicesBillingHistory'
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: '/InventoryManagement'
  },
  TechnicianManagement: {
    component: TechnicianManagement,
    path: '/TechnicianManagement'
  },
  AddTechnician: {
    component: AddTechnician,
    path: '/AddTechnician'
  },
  WarrantyRegistration: {
    component: WarrantyRegistration,
    path: '/WarrantyRegistration'
  },
  ServiceCallRequest: {
    component: ServiceCallRequest,
    path: '/ServiceCallRequest'
  },
  ManageBatteryCollection: {
    component: ManageBatteryCollection,
    path: '/ManageBatteryCollection'
  },

  TechnicianDashboard: {
    component: TechnicianDashboard,
    path: '/TechnicianDashboard'
  },
  UpcomingServiceCall: {
    component: UpcomingServiceCall,
    path: '/UpcomingServiceCall'
  },
  SocialMedia1: {
    component: SocialMedia1,
    path: '/SocialMedia1'
  },
  SocialMedia2: {
    component: SocialMedia2,
    path: '/SocialMedia2'
  },
  SocialMedia3: {
    component: SocialMedia3,
    path: '/SocialMedia3'
  },
  TechnicianProfile: {
    component: TechnicianProfile,
    path: '/TechnicianProfile'
  },
  EditProfile: {
    component: EditProfile,
    path: '/EditProfile'
  },
  Wishlist: {
    component: Wishlist,
    path: '/Wishlist'
  },
  AboutUs: {
    component: AboutUs,
    path: '/AboutUs'
  },
  ShippingPolicy: {
    component: ShippingPolicy,
    path: '/ShippingPolicy'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  MediaGallery: {
    component: MediaGallery,
    path: '/Gallery',
    exact:true
  },
  Photos: {
    component: Photos,
    path: '/Gallery/Photos',
    exact:true
  },
  Video: {
    component: Video,
    path: '/Gallery/Videos'
  },
  SearchBatteryResult: {
    component: SearchBatteryResult,
    path: '/SearchBatteryResult/:id',
    exact:true
  },
  EditTechnician: {
    component: EditTechnician,
    path: '/EditTechnician'
  },
  ManageTimeSlots: {
    component: ManageTimeSlots,
    path: '/ManageTimeSlots'
  },

 
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <Nav />

        
        {WebRoutesGenerator({ routeMap })}
       
        <FooterSection />
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;
